// $sponsorSliderWidth:100vw;
$sponsorSliderWidth:$maxWidth;
$itemOuterHeight:225px;
$itemOuterWidth:320px;

// most of this just negates existing sitebuilder css
.sponsor-slider {
  display: none; //override this puppy when functions fire
  float: none;
  &.flexslider {
    line-height: initial;
    border: none;
    border-radius: 0;
    width: 100%;
    left: 0px;

    // uncomment if desired width is 100vw
    // .page-manager-visible & {
    //   width: calc(#{$sponsorSliderWidth} - (60px));
    //   left:calc(-1*(#{$sponsorSliderWidth} - (60px) - 100%) / 2);
    // }
    // width: $sponsorSliderWidth;
    // left:calc(-1*(#{$sponsorSliderWidth}) - 100%) / 2);

    // uncomment if desired width is less than 100vw
    max-width: $sponsorSliderWidth;
    @media screen and (min-width:$maxWidth + 60px) {
      left:calc(-1*(#{$sponsorSliderWidth} - 100%) / 2);
    }
  }
  & .column {
    position: initial;
    min-height: initial;
    padding-left: 0px;
    padding-right: 0px;
    float:none;
    box-sizing: content-box;
    .pageEl:before {
      content:none;
    }
  }
  & .pageElement.heroPhotoElement,
  & .pageElement.heroPhotoElement > a { /* added this line */
    text-align: center;
    display: flex;
    float: none;
    margin: initial;
    width: initial;
    clear: initial;
    height: $itemOuterHeight;
    & > img {
      display: initial;
      border: none;
      margin: auto;
      height: auto;
      width: auto;
      max-height: calc(100% - 100px);
      max-width: calc(100% - 100px);
    }
  }
  & .pageElement.heroPhotoElement > a { /* added this bit here */
    width: 100%;
  }
  &[data-oversizedslider="true"] {
    .column {
      text-align: center;
      width: initial !important;
      justify-content: center;
      display: flex;
    }
    .flex-direction-nav {display:none;}
  }
}
// border styles

.sponsor-slider {
  .pageEl {
    border: 1px solid #ffffff;
    border-left-width: 0px;
    box-sizing: content-box;
    &:last-of-type {
      border-right-width: 0px;
      width: $itemOuterWidth !important;
    }
  }
  .flex-direction-nav a {
    border: 1px solid #ffffff;
  }
  &[data-oversizedslider="true"] {
    .pageEl{
      &:first-of-type {
        border-left: 1px solid #ffffff;
      }
      &:last-of-type {
        border-right-width: 1px;
      }
    }
  }
}

// end border styles
// background

//flexslider CSS defaults to a white background so to negate that remove this if statement & keep the sponsorSlider.backgroundColor object as inherit
.sponsor-slider.flexslider {
  background-color: #ffffff;
}

.sponsor-slider {
  .pageEl {
    background-color: #ffffff;
  }
}

.sponsor-slider {
  .flex-direction-nav a {
    background-color: #ffffff;
  }
}

// end background
// arrow animation style

.sponsor-slider.flexslider .flex-direction-nav .flex-prev,
.sponsor-slider.flexslider .flex-direction-nav .flex-next,
.sponsor-slider.flexslider:hover .flex-direction-nav .flex-prev,
.sponsor-slider.flexslider:hover .flex-direction-nav .flex-next {
    opacity: 1;
}
.sponsor-slider.flexslider:hover .flex-direction-nav .flex-prev {
    left: 0px;
}
.sponsor-slider.flexslider:hover .flex-direction-nav .flex-next {
    right: 0px;
}
.sponsor-slider.flexslider {
    margin: 0px;
}
.sponsor-slider .flex-viewport {
    width: calc(100% - 100px);
    margin: 0px auto;
    position: relative;
}

// end arrow animation style
// arrow styles

.sponsor-slider .flex-direction-nav a {
    height: 100%;
    top: 0;
    background-color: #ffffff;
    margin: 0;
}
.sponsor-slider .flex-direction-nav a.flex-next {
  right: 0px;
}
.sponsor-slider .flex-direction-nav a.flex-prev {
  left: 0px;
}
.sponsor-slider .flex-direction-nav a:before {
  height: 100%;
  width: 100%;
  line-height: $itemOuterHeight;
  text-align: center;
}

// end rectangle styles
// arrow width

.sponsor-slider .flex-direction-nav a {
  width: 50px;
}

// end arrow width
// arrow styles
// this is wrapping ths liquid conditonal formatting here b/c the settings we want to change are all within one CSS selector chain
.sponsor-slider .flex-direction-nav a:before {
  font-family: 'FontAwesome';
  font-size: 40px;
  color: #cccccc;
  text-shadow: none;
  content: '\f104';
  font-weight: 900;
  transition: color 300ms;
}
.sponsor-slider .flex-direction-nav a:hover:before {
  color:$link-color;
}
.sponsor-slider .flex-direction-nav a.flex-next:before {
  content: '\f105';
  font-weight: 900;
}
