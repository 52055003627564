// 1. Variable defaults
// 2. Base Styles

// Variables
$page-element-margin: 15px !default;

// Base Styles
.pageEl {
  @extend %clearfix;
  > .pageElement{
    float: left;
  }
}
.pageElement {
  width: 100%;
  margin: $page-element-margin 0;
  clear: both;
  .pageElement{
    // unsure why this is neccessary, but including it in case
    margin-left: 0;
    margin-right: 0;
  }
  .theme-search-bar &.sn-social-media-list {
    margin:0;
    .sn-social-media-icon {
      height: 26px;
      width: 26px;
      line-height: 44px;
      margin: 0;
      &:before {
        font-size: 15px;
        line-height: 26px;
        width: 26px;
      }
      &[class*="icon-"]:hover {
        background-color: $link-color;
      }
    }
  }
  .snFooterContainer &.sn-social-media-list {
    .sn-social-media-icon {
      height: 36px;
      width: 36px;
      &:before {
        font-size: 15px;
        line-height: 36px;
        width: 36px;
      }
      &[class*="icon-"]:hover {
        background-color: $link-color;
      }
    }
  }
}
