// 5. Site Footer
.snFooterContainer {
  margin: 0 auto;
  background-color: #464646;
  .sn-social-media-list:before,
  .layoutContainer h3 {
    font-size: 17px;
    font-weight: 900;
    color: #ffffff;
    padding-top: 35px;
    margin-bottom: 25px
  }
  .sn-social-media-list.dark .sn-social-media-icon { background: #959595; }
  @media only screen and (min-width: 768px){
    .footer-columns {
      display: flex;
      padding-bottom: 50px;

      .column {
        flex-grow: 1;
        width: auto !important;

        &:last-of-type {
          flex-grow: 3;
          text-align: left;
        }

      }

    }
  }
  .layoutContainer .hide-header {

    h3 {
      color: #464646;

      @media only screen and (max-width: 768px){ display: none; }

    }

  }
  .sn-social-media-list:before {
    content: 'Follow Our Story';
    display: block;
    text-transform: uppercase;
    font-family: $fontOne;
  }
  .text a,
  .text p,
  .text div,
  .text span,
  .text td,
  .text tr,
  .text th,
  .text address {
    font-family: 'Yantramanav';
    color: #cccccc;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 400;
    padding: 5px 0px !important;
  }
  .text address {
    padding: 0px !important;
    font-style: normal;
  }
  .pageEl .textBlockElement table td,
  .pageEl .textBlockElement table th {
    padding: 0;
  }
  #siteFooter {
    width: 100vw;
    left: calc((100vw - 100%)/-2);
    .page-manager-visible & {
      left: calc(((100vw - -60px) - 100%)/-2);
    }
    background-color: #333333;
    a, p, li {
      font-size: 11px;
      color: #ffffff;
      font-family: $fontTwo;
    }
    li#poweredByNGIN a img {
      display: block;
    }
    li#poweredByNGIN a:after {
      content: none;
    }
    li#poweredByNGIN {
      float: right;
      margin-top: 5px;
      display: none;
    }
    li:empty {
      display: none;
    }
  }
  @media screen and (max-width:768px) {
    &,
    & .layoutContainer h3,
    & td {
      text-align: center !important;
      width: 100%;
      display: block;
    }
    .pageElement {
      margin-top: 0;
      margin-bottom: 0;
    }
    & .sn-social-media-list:before,
    & .layoutContainer h3 {
      margin-bottom: 5px;
    }
    & .layoutContainer {
      margin-bottom: 40px;
    }
    #siteFooter {
      padding-top: 25px;
      li {
        display: block;
        width: 100%;
        line-height: 30px;
        &#poweredByNGIN a {
          display: inline-block;
        }
      }
    }
  }
}

#siteFooter {
  @extend %clearfix;
  display: block;
  margin: auto;
  padding: 10px 20px;
  position: relative;
  vertical-align: bottom;
  width: 100%;
  font-size: .8em;
  clear: both;

  > ul {
    padding: 0;
    @include displayCenter($maxWidth);

    li {
      display: inline-block;
      padding: 0 .2em;
      min-height: 20px;

      a {
        display: inline-block;
      }

      &:hover {
        display: inline-block;
      }
    }
  }
}

#siteFooter li#poweredByNGIN {
  a {
    display: block;
    width: 120px;
    height: 20px;
    position: relative;

    &:after {
      content: url($asset-path-for+'/logo_images/logo_black.svg');
      display: block;
      position: absolute;
      top: 4px;
      left: 0;
      width: 100%;
    }

    img {
      display: none;
    }
  }
}

@media only screen and (max-width: 1024px) {
  #siteFooter {
    li {
      min-height: 0;
    }
  }

  #siteFooter li#poweredByNGIN {
    padding: 10px 0;
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  #siteFooter {
    li {
      min-height: 0;
    }
  }

  #siteFooter li#poweredByNGIN {
    padding: 10px 0;
  }
}
