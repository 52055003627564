/* Layout Containers
---------------------------------------------------------------------------------------------------- */

.layoutContainer {
  .column1,
  .column2,
  .column3,
  .column4 {
    float: left;
    min-height: 1px;
  }
}

#layoutsBin .layoutContainer {
  padding-left: 0;
}

.layoutContainer .layoutContainer {
  padding-bottom: 15px;
}

/* Container Widths
---------------------------------------------------------------------------------------------------- */

.oneColumn .column1,
.layoutContainer .oneColumn .column1 {
  width: 100%;
}

.twoColumn2575 .column2,
.twoColumn7525 .column1 {
  width: 75%;
}

.twoColumn6633 .column1,
.twoColumn3366 .column2,
.layoutContainer .twoColumn6633 .column1,
.layoutContainer .twoColumn3366 .column2 {
  width: 66.6%;
}

.twoColumn5050 .column1,
.twoColumn5050 .column2,
.threeColumn502525 .column1,
.threeColumn255025 .column2,
.threeColumn252550 .column3,
.layoutContainer .twoColumn5050 .column1,
.layoutContainer .twoColumn5050 .column2,
.layoutContainer .threeColumn502525 .column1,
.layoutContainer .threeColumn255025 .column2,
.layoutContainer .threeColumn252550 .column3 {
  width: 50%;
}

.threeColumn333333 .column1,
.twoColumn6633 .column2,
.twoColumn3366 .column1,
.threeColumn333333 .column2,
.threeColumn333333 .column3,
.layoutContainer .threeColumn333333 .column1,
.layoutContainer .twoColumn6633 .column2,
.layoutContainer .twoColumn3366 .column1,
.layoutContainer .threeColumn333333 .column2,
.layoutContainer .threeColumn333333 .column3 {
  width: 33.3%;
}

.twoColumn2575 .column1,
.twoColumn7525 .column2,
.threeColumn502525 .column2,
.threeColumn502525 .column3,
.threeColumn255025 .column1,
.threeColumn255025 .column3,
.threeColumn252550 .column1,
.threeColumn252550 .column2,
.fourColumn25252525 .column1,
.fourColumn25252525 .column2,
.fourColumn25252525 .column3,
.fourColumn25252525 .column4,
.layoutContainer .threeColumn502525 .column2,
.layoutContainer .threeColumn502525 .column3,
.layoutContainer .threeColumn255025 .column1,
.layoutContainer .threeColumn255025 .column3,
.layoutContainer .threeColumn252550 .column1,
.layoutContainer .threeColumn252550 .column2,
.layoutContainer .fourColumn25252525 .column1,
.layoutContainer .fourColumn25252525 .column2,
.layoutContainer .fourColumn25252525 .column1,
.layoutContainer .fourColumn25252525 .column2 {
  width: 25%;
}

/* Fluid Layout Exceptions
---------------------------------------------------------------------------------------------------- */


.oneColumn .condensedView,
.twoColumn6633 .column1 .condensedView,
.twoColumn3366 .column2 .condensedView {
  display: none;
}

.oneColumn .twoColumn6633 .column2 .condensedView,
.oneColumn .twoColumn3366 .column1 .condensedView,
.oneColumn .twoColumn5050 .condensedView,
.oneColumn .threeColumn333333 .condensedView,
.oneColumn .threeColumn502525 .condensedView,
.oneColumn .threeColumn255025 .condensedView,
.oneColumn .threeColumn252550 .condensedView {
  display: block;
}

.oneColumn .twoColumn6633 .column2 .condensedView,
.oneColumn .twoColumn3366 .column1 .condensedView,
.oneColumn .twoColumn5050 .condensedView,
.oneColumn .threeColumn333333 .condensedView,
.oneColumn .threeColumn502525 .condensedView,
.oneColumn .threeColumn255025 .condensedView,
.oneColumn .threeColumn252550 .condensedView {
  display: block;
}

.oneColumn .twoColumn6633 .column2 tr .condensedView,
.oneColumn .twoColumn3366 .column1 tr .condensedView,
.oneColumn .twoColumn5050 tr .condensedView,
.oneColumn .threeColumn333333 tr .condensedView,
.oneColumn .threeColumn502525 tr .condensedView,
.oneColumn .threeColumn255025 tr .condensedView,
.oneColumn .threeColumn252550 tr .condensedView {
  display: table-cell;
}


/*.twoColumn5050 .layoutContainer, .twoColumn6633 .layoutContainer, .twoColumn3366 .layoutContainer, .threeColumn502525 .layoutContainer, .threeColumn255025 .layoutContainer, .threeColumn252550 .layoutContainer{
  display: none;
}
*/
.layoutContainer{
  .column1,
  .column2,
  .column3{
    .newsItemColumn{
      display: block;
    }
  }
}

/* Antonyms
---------------------------------------------------------------------------------------------------- */
.pe25  .noIn25,
.pe33  .noIn33,
.pe50  .noIn50,
.pe66  .noIn66,
.pe75  .noIn75,
.pe100 .noIn100 {
  display: none;
}

// .full-width
.user_mode .full-width {
  width: 100vw;
  margin-left: calc((100vw - 100%)/-2);
  .page-manager-visible & {
    width: calc(100vw - 60px);
    margin-left: calc(((100vw - 60px) - 100%)/-2);
  }
}

// .contain-columns
// 1200px is the sitecontainer width in this case, adjust for other widths...
.edit_mode #layoutsBin .layoutContainer.contain-columns,
.layoutContainer.contain-columns,
.contain-columns {
  padding-left: calc((100vw - #{$maxWidth})/2);
  padding-right: calc((100vw - #{$maxWidth})/2);
  .page-manager-visible & {
    padding-left: calc(((100vw - 60px) - #{$maxWidth})/2);
    padding-right: calc(((100vw - 60px) - #{$maxWidth})/2);
  }
}

// .no-padding
.no-padding {
  .column {
    padding-left:0px;
    padding-right: 0px;
  }
}

// no margin
.has-main-nav .no-margin {
  margin:0;
  .pageElement {
    margin:0;
  }
}

// .column-padding-10
.column-padding-10 {
  [class*="col-"] {
    padding-left:10px;
    padding-right:10px;
  }
}
// .column-padding-20
.column-padding-20 {
  [class*="col-"] {
    padding-left:20px;
    padding-right:20px;
  }
}
// .column-padding-30
.column-padding-30 {
  [class*="col-"] {
    padding-left:30px;
    padding-right:30px;
  }
}
// .column-padding-40
.column-padding-40 {
  [class*="col-"] {
    padding-left:40px;
    padding-right:40px;
  }
}
// .column-padding-50
.column-padding-50 {
  [class*="col-"] {
    padding-left:50px;
    padding-right:50px;
  }
}
// .column-padding-60
.column-padding-60 {
  [class*="col-"] {
    padding-left:60px;
    padding-right:60px;
  }
}
// .column-padding-70
.column-padding-70 {
  [class*="col-"] {
    padding-left:70px;
    padding-right:70px;
  }
}
// .column-padding-80
.column-padding-80 {
  [class*="col-"] {
    padding-left:80px;
    padding-right:80px;
  }
}
// .column-padding-90
.column-padding-90 {
  [class*="col-"] {
    padding-left:90px;
    padding-right:90px;
  }
}
// .column-padding-100
.column-padding-100 {
  [class*="col-"] {
    padding-left:100px;
    padding-right:100px;
  }
}

// 1px column borders
[class*="column-border-"] {
  .column {
    border-left:1px solid transparent;
    &:first-of-type {
      border-left:0;
    }
  }
}
.column-border-white {
  .column {
    border-color:#ffffff;
    @media only screen and (max-width: 768px){
      border-left: none;
      position: relative;
      padding: 10px;

      &:after {
        content: '';
        display: inline-block;
        height: 1px;
        width: 40%;
        background-color: white;
        position: absolute;
        bottom: 5px;
        left: 30%;
      }

      &:last-of-type:after { display: none; }


    }
  }
}
.column-border-grey,
.column-border-gray {
  .column {
    border-color: #dad9d9;
    border-right: solid 1px #fdfdfd;

    &:last-of-type { border-right: none; }

    @media only screen and (max-width: 768px){
      border-left: none;
      border-right: none;
      border-top: solid 1px #dad9d9;
      border-bottom: solid 1px #fdfdfd;

      &:first-of-type { border-top: none; }
      &:last-of-type { border-bottom: none; }

    }

  }
}

.font-family-roboto-condensed {
  font-family:$fontOne;
}

.has-main-nav .user_mode {
  .padding-around-10{
    padding-top:10px;
    padding-bottom:10px;
  }
  .padding-around-20{
    padding-top:20px;
    padding-bottom:20px;
  }
  .padding-around-30{
    padding-top:30px;
    padding-bottom:30px;
  }
  .padding-around-40{
    padding-top:40px;
    padding-bottom:40px;
  }
  .padding-around-50{
    padding-top:50px;
    padding-bottom:50px;
  }
  .padding-around-60{
    padding-top:60px;
    padding-bottom:60px;
  }
  .padding-around-70{
    padding-top:70px;
    padding-bottom:70px;
  }
  .padding-around-80{
    padding-top:80px;
    padding-bottom:80px;
  }
  .padding-around-90{
    padding-top:90px;
    padding-bottom:90px;
  }
  .padding-around-100{
    padding-top:100px;
    padding-bottom:100px;
  }
  .padding-around-110{
    padding-top:110px;
    padding-bottom:110px;
  }
  .padding-around-120{
    padding-top:120px;
    padding-bottom:120px;
  }
  .padding-around-130{
    padding-top:130px;
    padding-bottom:130px;
  }
  .padding-around-140{
    padding-top:140px;
    padding-bottom:140px;
  }
  .padding-around-150{
    padding-top:150px;
    padding-bottom:150px;
  }
}

// .layout-container-background-1
// .layout-container-background-1 {
//   background-image: url('https://s3.amazonaws.com/assets.ngin.com/attachments/background_graphic/9037/2677/alternate-background-1.jpg');
//   background-position: center center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-color: $link-color;
//   /*target & to keep style adjustments specific to this site separate from styles only required for the portable portion of the layout-container-background-1 class*/
// }

// .layout-container-background-2
// .layout-container-background-2 {
//   background-image: url('https://s3.amazonaws.com/assets.ngin.com/attachments/background_graphic/9037/2702/alternate-background-2.jpg');
//   background-position: center center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-color: $link-color;
//   /*target & to keep style adjustments specific to this site separate from styles only required for the portable portion of the layout-container-background-1 class*/
// }

.layout-container-background-grey,
.layout-container-background-gray {
  background-color:#eeeeee;
}

.layout-container-background-purple {
  background-color:$link-color;

  .has-mobile-nav & {
    padding: 15px 0;

    .text {
      padding: 0 15px;

      p { text-align: center !important; }

    }

    .linkElement h4 { text-align: center; }

  }

}

.white-text {
  h1,h2,h3,h4,h5,h6,a,p,div,ol,ul,li,span,blockquote,address,pre,code,b,i,em,strong {
    color: #ffffff;
  }
}

.white-background {
  background: #ffffff;
  padding: 45px 50px 55px;
  .pageElement {
    margin-top:0;
  }
}

.title-font-size-30 {
  h3 {
    font-size: 30px;
  }
}

// Container Background Image
.edit_mode .container-bg { display: block; }
.user_mode .container-bg {
  display: none;

  @media only screen and (max-width:768px){
    display: block;

    .pageElement { margin: 0; }

  }

}

.has-container-bg {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin: 0 15px;

  @media only screen and (max-width:768px) { background-image: none; }

}

@media only screen and (max-width: 768px){
  .mobile-center, .mobile-center h3 { text-align: center !important; }
}
