$site-logo-size: $site-tagline-height + $main-nav-height + $sub-nav-height + 3;
$site-logo-size-fixed: $site-tagline-height-fixed + $main-nav-height-fixed + $sub-nav-height-fixed + 3;

#sn-site-logo {
  position: absolute;
  top: $site-tagline-height + 1;
  left: 50%;
  margin-left: 0 - $maxWidth/2;
  width: $site-logo-width;
  height: $site-logo-height;
  padding: 0px;
  background-color: transparent;
  border: 0px;
  z-index: 1000;
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: top 300ms, height 300ms;

  @media only screen and (max-width:1275px){
    left: 0;
    margin-left: 0;
  }

  .nav-fixed & {
    top: 6px;
    height: $site-logo-size-fixed-height;
    width: $site-logo-width;
  }

  .sn-site-logo-wrap {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }

  .sn-site-logo-background {
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    -webkit-background-size: contain;
  }
}
